import { ILogin } from "../model/login.model";
import client from "../utils/client";

export default class AuthService {
  prefix = "auth";

  login = (data: Types.LoginRequest): Promise<Types.RequestResponse<ILogin>> =>
    client.create(`${this.prefix}/users/login`, data);

  resetPassword = (email: string): Promise<Types.RequestResponse<null>> =>
    client.create(`${this.prefix}/users/forgot-password`, { email: email });

  verifyEmail = (email: string): Promise<Types.RequestResponse<null>> =>
    client.create(`${this.prefix}/users/verification-email`, { email: email });

  refreshToken = (): Promise<Types.RequestResponse<ILogin>> =>
    client.create(`${this.prefix}/users/renew-token`, null);
}
