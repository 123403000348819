import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import localEN from "../../public/translations/en/translation.json";
import localSV from "../../public/translations/sv/translation.json";

// the translations

const resources: Types.ObjectMap = {
  en: {
    translation: localEN,
  },
  sv: {
    translation: localSV,
  },
};
let setLanguage: any = undefined;

i18n.use(initReactI18next).init({
  resources: resources,
  lng: "en",
  keySeparator: "__",

  interpolation: {
    escapeValue: false,
  },
});

setLanguage = (lang: string) => {
  if (resources[lang]) {
    i18n.changeLanguage(lang, (error, _) => {
      if (error) {
        console.log(error);
        i18n.changeLanguage("en");
      }
    });
  }
};

export const set = setLanguage;

export default i18n;
