import { ProviderConfig } from "launchdarkly-react-client-sdk";

const config = {
  hospitadApiUrl: process.env.hospitadApiUrl,
  internalApi: process.env.NEXT_PUBLIC_internalApi,
  appEnv: process.env.NEXT_PUBLIC_appEnv,
  gtagTrackingId: process.env.NEXT_PUBLIC_gtagTrackingId,
  sentryDsn: process.env.NEXT_PUBLIC_sentry_dsn,
  tawktoPropertyId: process.env.NEXT_PUBLIC_TAWKTO_PROPERTY_ID,
  tawktoWidgetId: process.env.NEXT_PUBLIC_TAWKTO_WIDGET_ID,
  tawktoWidgetIdNew: process.env.NEXT_PUBLIC_TAWKTO_WIDGET_ID_NEW,
  launchDarklyClientId: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID,
  heapId: process.env.NEXT_PUBLIC_HEAP_ID,
  hotjarId: process.env.NEXT_PUBLIC_HOTJAR_ID,
};

const lunchDarklyConfig: ProviderConfig = {
  clientSideID: config.launchDarklyClientId!,
  options: { bootstrap: "localStorage" },
};
export const serviceRageDate = {
  longStartDate: new Date("2000-01-01 00:00:00").toISOString().split("T")[0],
  startDate: new Date("2022-05-18 00:00:00").toISOString().split("T")[0],
  endDate: new Date().toISOString().split("T")[0],
};
export { lunchDarklyConfig };

export default config;
