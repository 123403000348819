import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apiRequestHandler } from "../api/requestHandler";
import { EAPP_ROUTE } from "../enums/route.enum";
import { useAuthUser } from "../hooks/use-auth-user";
import {
  useIsOnboarded,
  useIsShareInvoiceEmailOnboarding,
  useIsSignedDocument,
} from "../hooks/use-onboarding";
import { useOnboardingPage } from "../hooks/use-onboarding-page";
import { usePublicPage } from "../hooks/use-public-page";
import { IReduxState } from "../redux/app_store";
import { PageLoader } from "./share/page-loader";
import UserData from "./user-data";

interface IProps {
  children: React.ReactNode;
}

interface IFcm {
  deviceTag: String;
  model: String;
  fcmToken?: String;
  logged?: boolean;
  active?: boolean;
  sent?: boolean;
}

export const AppRouter: React.FC<IProps> = ({ children }) => {
  const router = useRouter();
  const isAuth = useAuthUser();
  const isPublicPage = usePublicPage();
  const isOnboarded = useIsOnboarded();
  const isOnboardingPage = useOnboardingPage();
  const isShareInvoiceEmailOnboarding = useIsShareInvoiceEmailOnboarding();
  const isSignedDocument = useIsSignedDocument();
  const [tokenMessage, setTokenMessage] = useState<IFcm>();
  const { user } = useSelector((store: IReduxState) => store);

  const handleDevicesLoginRequest = async () => {
    await apiRequestHandler(
      `/api/hospitad/notification-generator/devices/${tokenMessage?.deviceTag}/login`,
      "PUT",
      {
        isLoggedIn: !tokenMessage?.logged,
      },
      {
        "Content-Type": "application/json",
      }
    );
  };

  const handleDevicesRequest = async () => {
    const resp = await apiRequestHandler(
      `/api/hospitad/notification-generator/devices`,
      "POST",
      {
        fcmToken: tokenMessage?.fcmToken,
        isActive: tokenMessage?.active,
        isLoggedIn: tokenMessage?.logged,
        model: tokenMessage?.model,
        uniqueTag: tokenMessage?.deviceTag,
      },
      {
        "Content-Type": "application/json",
      }
    );
    alert(resp);
  };

  useEffect(() => {
    const onMessage = (message) => {
      const parsedMessage: IFcm = JSON.parse(message.data);
      if (
        tokenMessage?.sent ||
        (!tokenMessage?.fcmToken && parsedMessage.fcmToken)
      ) {
        if (
          tokenMessage?.fcmToken &&
          parsedMessage.logged !== tokenMessage?.logged
        ) {
          handleDevicesLoginRequest();
          parsedMessage.sent = true;
        }
        if (parsedMessage.fcmToken) {
          setTokenMessage(parsedMessage);
        }
      }
    };

    // @ts-ignore
    if (window.ReactNativeWebView)
      document.addEventListener("message", onMessage);

    return () => {
      // @ts-ignore
      if (window.ReactNativeWebView)
        document.removeEventListener("message", onMessage);
    };
  }, []);

  useEffect(() => {
    if (tokenMessage?.fcmToken && !tokenMessage?.sent && user?.id) {
      handleDevicesRequest();
      setTokenMessage({
        ...tokenMessage,
        sent: true,
      } as IFcm);
    }
  }, [isAuth, tokenMessage, user?.id]);

  if (!isAuth && !isPublicPage) {
    router.push(EAPP_ROUTE.LOGIN);
    return <PageLoader />;
  }

  if (isAuth && isOnboarded && isOnboardingPage) {
    router.push(EAPP_ROUTE.MAIN);
    return <PageLoader />;
  }

  if (
    isAuth &&
    isOnboardingPage &&
    isSignedDocument &&
    router.pathname !== EAPP_ROUTE.SIT_BACK_AND_RELAX
  ) {
    router.push(EAPP_ROUTE.SIT_BACK_AND_RELAX);
    return <PageLoader />;
  }

  if (
    isAuth &&
    isShareInvoiceEmailOnboarding &&
    router.pathname !== EAPP_ROUTE.POWER_OF_ATTORNEY
  ) {
    router.push(EAPP_ROUTE.POWER_OF_ATTORNEY);
    return <PageLoader />;
  }

  if (
    isAuth &&
    !isOnboarded &&
    !isSignedDocument &&
    !isShareInvoiceEmailOnboarding &&
    !isOnboardingPage &&
    router.pathname !== EAPP_ROUTE.START_ONBOARDING
  ) {
    router.push(EAPP_ROUTE.START_ONBOARDING);
    return <PageLoader />;
  }

  if (isAuth && isPublicPage) {
    router.push(EAPP_ROUTE.MAIN);
    return <PageLoader />;
  }
  //@ts-ignore
  if (isAuth && !isPublicPage) return <UserData>{children}</UserData>;

  return <>{children}</>;
};
